import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { AlegraEstimate } from "alegra-node-api";
import cogoToast from "cogo-toast";
import dayjs from "dayjs";
import { ClipboardList, CloudUpload, DocumentText, Eye, Pencil, Trash } from "heroicons-react";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaunaPaymentStatus, HasuraOrderStatus } from "../enums";
import { api, orderToEstimate } from "../utils/api";
import {
  DELETE_ORDER_MUTATION,
  GET_AVAILABLE_STOCK,
  GET_STOCK_BY_IDS,
  UPDATE_ORDER_MUTATION,
  UPDATE_STOCK_HISTORY,
  UPDATE_STOCK_MULTIPLE_MUTATION,
} from "../utils/gqlQueries";
import { orderStockResolution } from "../utils/stocking";
import Dropdown from "./Dropdown";
import { OrderPaymentBadge, OrderStatusBadge, isOrderPaid } from "./OrderBadges";
import OrderContextEditNote from "./OrderContextEditNote";

TimeAgo.addLocale(es);

const OrderContextMenu = ({ order, refetchOrders }: { order: HasuraOrder; refetchOrders: Function }) => {
  const { refetch: refetchStock } = useQuery(GET_AVAILABLE_STOCK);
  const [updateOrderMutation] = useMutation(UPDATE_ORDER_MUTATION);
  const [deleteOrderMutation] = useMutation(DELETE_ORDER_MUTATION);
  const [updateStockMutation] = useMutation(UPDATE_STOCK_MULTIPLE_MUTATION);
  const [updateStockHistoryMutation] = useMutation(UPDATE_STOCK_HISTORY);
  const [createEstimate] = api.estimates.create();
  const [creatingEstimate, setCreatingEstimate] = useState(false);
  const [updatingInvoice, setUpdatingInvoice] = useState(false);
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const client = useApolloClient();
  // const [sendEmail] = api.estimates.sendEmail();
  const orderId = order?.id;

  const updateStatus = async (status: HasuraOrderStatus) => {
    let updateBody: Partial<HasuraOrder> = {
      status,
      statusDates: { ...order.statusDates, [status]: dayjs().format("YYYY-MM-DD") },
    };
    if (status === HasuraOrderStatus.Finalizado) {
      updateBody = { ...updateBody };
    }
    if (order.status === HasuraOrderStatus.Produccion && status === HasuraOrderStatus.Despacho) {
      const stockRes = await client.query({
        query: GET_AVAILABLE_STOCK,
        fetchPolicy: "no-cache",
      });
      const { newItems } = orderStockResolution(order, stockRes?.data?.stock);

      const anyMissingItems = newItems.find((i) => i.missingUnits > 0);
      updateBody.lots = newItems.map((i) => i.usedLots).flat();
      if (anyMissingItems) {
        cogoToast.error("No hay suficiente stock para despachar esta orden");
        return;
      }
      updateBody.lots.forEach((lot, index) => {
        updateStockMutation({
          variables: {
            id: lot.id,
            data: {
              unusedQty: lot.unusedQty,
            },
            history: {
              orderId: order.id,
              taken: lot.taken,
              date: new Date(),
            },
          },
        });
      });
    }
    if (order.status === HasuraOrderStatus.Despacho && status === HasuraOrderStatus.Produccion) {
      const accept = window.confirm(
        "Estas devolviendo la orden a Produccion y se devolvera el stock a sus lotes, estas segura?"
      );
      if (!accept) return;
      updateBody.lots = [];
      const stockRes = await client.query<{ stock: HasuraStock[] }>({
        query: GET_STOCK_BY_IDS,
        variables: {
          ids: order.lots.map((i) => i.id),
        },
      });

      stockRes.data.stock.forEach((stock) => {
        const index = stock.history?.findIndex((l) => l.orderId === order.id);
        const taken = stock.history?.[index || 0]?.taken;
        if (index !== undefined && taken !== undefined) {
          updateStockHistoryMutation({
            variables: {
              id: stock.id,
              _delete_at_path: {
                history: index + "",
              },
              unusedQty: stock.unusedQty + taken,
            },
          });
        }
      });
    }
    await updateOrderMutation({
      variables: {
        id: order.id,
        data: updateBody,
      },
    });
    if (refetchOrders) refetchOrders();
    refetchStock();
  };

  const updateNotes = async (notes: string) => {
    await updateOrderMutation({
      variables: { id: order.id, data: { notes } },
    });
    setNotesModalOpen(false);
    if (refetchOrders) refetchOrders();
  };

  const updatePayment = async (payment: FaunaPaymentStatus) => {
    await updateOrderMutation({ variables: { id: order.id, data: { payment } } });
    if (refetchOrders) refetchOrders();
  };

  const updateOrderEstimation = async (estimate: AlegraEstimate) => {
    await updateOrderMutation({ variables: { id: order.id, data: { estimate } } });
    if (refetchOrders) refetchOrders();
  };

  const handleCreateQuote = async () => {
    const quote = orderToEstimate(order);
    setCreatingEstimate(true);
    try {
      const res = await createEstimate(quote);
      updateOrderEstimation(res);
      cogoToast.success("Cotizacion enviada exitosamente");
    } catch (err) {
      cogoToast.error("La cotizacion no pudo ser enviada a Alegra");
    } finally {
      setCreatingEstimate(false);
    }
  };

  const handleDeleteOrder = async () => {
    const sure = window.confirm("Natis? estas segura? 🤔");
    if (sure) {
      await deleteOrderMutation({ variables: { id: orderId } });
      if (refetchOrders) refetchOrders();
    }
  };

  // const handleSendQuote = () => {
  //   if (order.estimate) {
  //     sendEmail({ id: order.estimate.id, data: { emails: ["info.nutti@gmail.com"], sendCopyToUSer: true } });
  //   }
  // };

  const updateInvoiceInfo = async () => {
    if (order.estimate) {
      try {
        setUpdatingInvoice(true);
        const res = await api.axiosInstance.post<AlegraEstimate>("/alegraAdmin", {
          method: "estimates.get",
          data: { fields: "invoices" },
          id: order.estimate?.id,
        });
        if (res.data?.invoices?.[0]?.id) {
          const invoice = res.data?.invoices?.[0];
          await updateOrderMutation({
            variables: { id: order.id, data: { invoice: { id: invoice.id, numberTemplate: invoice.numberTemplate } } },
          });
          if (refetchOrders) refetchOrders();
        } else {
          cogoToast.info("🥴 Esta cotización no tiene factura");
        }
      } catch (err) {
        console.log(err);
      } finally {
        setUpdatingInvoice(false);
      }
    }
  };

  const toggleNotesModal = () => setNotesModalOpen(!notesModalOpen);

  return (
    <>
      <OrderContextEditNote order={order} open={notesModalOpen} onCancel={toggleNotesModal} onAccept={updateNotes} />
      <Dropdown>
        {order.status !== HasuraOrderStatus.Finalizado && (
          <>
            <Link to={`/orders/edit/${orderId}`} role="menuitem" className="btn-dropdown">
              <Pencil size={20} className="text-gray-500 mr-2" />
              Editar Orden de Compra
            </Link>

            <button role="menuitem" onClick={toggleNotesModal} className="btn-dropdown">
              <ClipboardList size={20} className="text-gray-500 mr-2" />
              Editar Notas
            </button>

            <div className="border-t border-gray-100"></div>
          </>
        )}
        <div className="px-4 py-1">
          <p className="text-xs leading-5 font-semibold">Cambiar estado de orden</p>
        </div>
        <div className="flex flex-wrap w-64 px-4 py-2">
          {Object.values(HasuraOrderStatus).map((key) => (
            <div key={key} className="w-1/2 pb-2">
              <button
                role="menuitem"
                onClick={() => updateStatus(key)}
                className="w-full pr-2 text-left"
                disabled={key === order.status}
              >
                <OrderStatusBadge status={key} className="w-full" />
              </button>
            </div>
          ))}
        </div>

        {!order.isReplacement && (
          <>
            <div className="border-t border-gray-100"></div>
            <div className="px-4 py-1">
              <p className="text-xs leading-5 font-semibold">Pagos</p>
            </div>
            {isOrderPaid(order) && (
              <button role="menuitem" onClick={() => updatePayment(FaunaPaymentStatus.Pagar)} className="btn-dropdown">
                <OrderPaymentBadge payment={FaunaPaymentStatus.Pagar} />
                Por Pagar
              </button>
            )}
            {!isOrderPaid(order) && (
              <button role="menuitem" onClick={() => updatePayment(FaunaPaymentStatus.Pagado)} className="btn-dropdown">
                <OrderPaymentBadge payment={FaunaPaymentStatus.Pagado} />
                Pagado
              </button>
            )}
            <div className="border-t border-gray-100"></div>
            <div className="px-4 py-1">
              <p className="text-xs leading-5 font-semibold">Alegra</p>
            </div>
            {!order.estimate && (
              <button role="menuitem" onClick={handleCreateQuote} disabled={creatingEstimate} className="btn-dropdown">
                <CloudUpload size={20} className="text-gray-500 mr-2" />
                {creatingEstimate ? "Creando cotizacion..." : "Crear cotización Alegra"}
              </button>
            )}
            {order.estimate && !order.invoice && (
              <button role="menuitem" onClick={updateInvoiceInfo} disabled={updatingInvoice} className="btn-dropdown">
                <DocumentText size={20} className="text-gray-500 mr-2" />
                {updatingInvoice ? "Actualizando..." : "Actualizar Info Facturación"}
              </button>
            )}
            {order.invoice && (
              <a
                href={`https://app.alegra.com/invoice/view/id/${order.invoice.id}`}
                // rel="noopener noreferrer"
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                role="menuitem"
                className="btn-dropdown"
              >
                <DocumentText size={20} className="text-gray-500 mr-2" />
                Ver factura
              </a>
            )}
            {order.estimate && (
              <>
                {/* <button role="menuitem" onClick={handleSendQuote} className="btn-dropdown">
                <AtSymbol size={20} className="text-gray-500 mr-2" />
                Enviar cotización
              </button> */}
                <a
                  href={`https://app.alegra.com/estimate/view/id/${order.estimate.id}`}
                  // rel="noopener noreferrer"
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                  role="menuitem"
                  className="btn-dropdown"
                >
                  <Eye size={20} className="text-gray-500 mr-2" />
                  Ver cotización
                </a>
              </>
            )}
          </>
        )}
        <div className="border-t border-gray-100"></div>
        <button role="menuitem" onClick={handleDeleteOrder} className="btn-dropdown">
          <Trash size={20} className="text-gray-500 mr-2" />
          Borrar Orden
        </button>
      </Dropdown>
    </>
  );
};

export default OrderContextMenu;
